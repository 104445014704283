import React from "react"
import Code from "../../components/Code"

const Services = ({ chain }) => {
  return chain.services ? (
    <React.Fragment>
      <h2>Services</h2>
      <p>
        These are the public services provided for {chain.name} by Brochain.
        Feel free to use them as long as you use them fairly.
      </p>
      <table cellPadding={0} cellSpacing={0}>
        <tbody>
          {chain.services.publicRpc ? (
            <tr>
              <th>Public RPC</th>
              <td>
                <Code>{chain.services.publicRpc}</Code>
              </td>
            </tr>
          ) : null}
          {chain.services.publicGrpc ? (
            <tr>
              <th>Public gRPC</th>
              <td>
                <Code>{chain.services.publicGrpc}</Code>
              </td>
            </tr>
          ) : null}
          {chain.services.publicRest ? (
            <tr>
              <th>Public API</th>
              <td>
                <Code>{chain.services.publicRest}</Code>
              </td>
            </tr>
          ) : null}
          {chain.services.stateSync &&
          chain.services.stateSync.rpc &&
          chain.services.stateSync.rpc !== chain.services.publicRpc ? (
            <tr>
              <th>State sync RPC</th>
              <td>
                <Code>{chain.services.stateSync.rpc}</Code>
              </td>
            </tr>
          ) : null}
          {chain.services.stateSync && chain.services.stateSync.peer ? (
            <tr>
              <th>State sync peer</th>
              <td>
                <Code>{chain.services.stateSync.peer}</Code>
              </td>
            </tr>
          ) : null}
          {chain.services.seedNode ? (
            <tr>
              <th>Seed node</th>
              <td>
                <Code>{chain.services.seedNode}</Code>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </React.Fragment>
  ) : null
}

export default Services
